.search-sec .serach-text p{
    font-size: 30px;
    font-weight: 600;
    color: #e62c2ceb;
    font-family: 'Cinzel', serif;
    text-align: center;
}

.search-box{
    width: fit-content;
    height: fit-content;
    position: relative;
  }
  .input-search{
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 10px;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    background-color: #22a6b3;
    padding-right: 40px;
    color:#000;
  }
  .input-search::placeholder{
    color:rgba(255,255,255,.5);
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
  }
  .btn-search{
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    color:#000 ;
    background-color:transparent;
    pointer-events: painted;  
  }
  .btn-search:focus ~ .input-search{
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
    color:#000;
  }
  .input-search:focus{
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
    color: #000;
  }

  .search-body{
    margin: 20px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bcb8ea;
  }

  .input-search::placeholder {
    color: #000 !important; /* Set your desired color */
  }
  
  .search-sec{
    padding: 20px 0;
  }