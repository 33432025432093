.hero-sec .hero-left img{
    width: 41%;
    position: absolute;
    top: 0%;
    height: 100vh;
    border-radius: 0 0 0 70px;
}

.hero-sec .hero-right{
    margin-top: 20%;
}

.hero-sec .hero-right .hero-tag .tag-one{
    font-size: 30px;
    font-weight: 600;
    color: #e62c2ceb;
    font-family: 'Cinzel', serif;
    
}

.hero-sec .hero-right .hero-tag .tag-two{
    font-size: 35px;
    font-weight: 600;
    text-transform: capitalize;
}

.hero-sec .hero-right .hero-tag .tag-three{
    font-size: 18px;
    text-transform: capitalize;
}

.hero-tag .btn-one{
    background-color: #e62c2ceb;
    border-radius: 10px;
    color: white;   
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border: none;
}

.hero-content .exc{
    margin-top: 10%;
    font-size: 100px;
    letter-spacing: 12px;
    font-weight: 900;
    opacity: 0.4;
    font-family: 'Cinzel', serif;
    text-shadow: 
    1px 0px 1px #ccc, 0px 1px 1px #eee, 
    2px 1px 1px #ccc, 1px 2px 1px #eee,
    3px 2px 1px #ccc, 2px 3px 1px #eee,
    4px 3px 1px #ccc, 3px 4px 1px #eee,
    5px 4px 1px #ccc, 4px 5px 1px #eee,
    6px 5px 1px #ccc, 5px 6px 1px #eee,
    7px 6px 1px #ccc;
}




