.nav-sec {
    width: 100%;
    padding: 1rem 1rem;
    margin: 0 auto;
  }

  .brand img{
    width: 6%;
    border-radius: 50%;
  }
  
  .header {
    z-index: 2;
    width: 100%;
    padding: 0.5rem;
  }
  .header .navbar .toggle {
    position: absolute;
    width: 2rem;
    height: 1.4rem;
    top: 2rem;
    right: 4rem;
    z-index: 2;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .header .navbar .toggle-btn {
    position: absolute;
    top: 0.5rem;
    right: 0;
    width: 1.6rem;
    height: 2px;
    background: #212121;
    transition: all 0.3s ease-in-out;
  }
  .header .navbar .toggle-btn::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    width: 2rem;
    height: 2px;
    background: #212121;
    transition: all 0.3s ease-in-out;
  }
  .header .navbar .toggle-btn::after {
    content: "";
    position: absolute;
    top: 0.5rem;
    width: 1.2rem;
    height: 2px;
    background: #212121;
    transition: all 0.3s ease-in-out;
  }
  .header .navbar .toggle-btn.open {
    transform: rotate(720deg);
    background: transparent;
  }
  .header .navbar .toggle-btn.open::before {
    transform: rotate(45deg) translate(5px, 8px);
  }
  .header .navbar .toggle-btn.open::after {
    width: 2rem;
    transform: rotate(-45deg) translate(3px, -6px);
  }
  .header .navbar .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    opacity: 1;
    visibility: hidden;
  }
  .header .navbar .menu.open {
    visibility: visible;
  }
  .header .navbar .menu-list {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    list-style: none;
    list-style-type: none;
    padding-right: 40rem;
    background: #ffffff;
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
  }
  .header .navbar .menu-list.open {
    transform: translateY(0);
  }
  .header .navbar .menu-item {
    transform: translateX(100vw);
    transition: all 0.3s ease-in-out;
  }
  .header .navbar .menu-item.open {
    transform: translateX(0);
  }
  .header .navbar .menu-link {
    display: inline-block;
    padding: 1rem 0;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: inherit;
    color: #212121;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }
  .header .navbar .menu-link:hover {
    color: #ff652f;
  }
  .header .navbar .menu .menu-list .menu-item:nth-child(1) {
    transition-delay: 0.25s;
  }
  .header .navbar .menu .menu-list .menu-item:nth-child(2) {
    transition-delay: 0.35s;
  }
  .header .navbar .menu .menu-list .menu-item:nth-child(3) {
    transition-delay: 0.45s;
  }
  .header .navbar .menu .menu-list .menu-item:nth-child(4) {
    transition-delay: 0.55s;
  }
  .header .navbar .brand {
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: inherit;
    color: #212121;
    text-transform: uppercase;
  }