.card {
    width: 90%;
    cursor: default;
    padding: 1.25rem;
    border-radius: 0.25rem;
    background-color: var(--sdcolor);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 30px;
  }
  
  .card:hover {
    transform: translateY(-0.5rem);
  }
  
  .card-img {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 0.25rem;
  }
  
  .card-img img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
  }
  
  .card-img figcaption {
    background-color: var(--ttcolor);
    color: var(--sdcolor);
    font-size: 0.85rem;
    padding: 0.5rem;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  
  .card-title {
    text-transform: capitalize;
    margin: 0.75rem 0;
  }

  .seraching-tittle{
    font-size: 32px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: fangsong sans-serif;
  }
  .box1{
  /* width: 45%;
    height: 6vh; */
    padding: 12px 24px;
    margin-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    text-transform: capitalize;
}