  
  .category-sec .service-card {
    width: 92%;
    display: inline-block;
    margin: 10px;
    box-sizing: border-box;
    height: 92%;
    padding: 2em 1.5em;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #fff;
    
  }
  
  .seraching-tittle{
    font-size: 32px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: fangsong sans-serif;
    padding: 17px 0;
  }

  
  .carousel-container {
    width: 100%; /* Ensure the container takes the full width */
  }
  
  .category-sec .service-card::after {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(#0dcaf0, rgb(255, 23, 131));
    position: absolute;
    left: 0%;
    top: -98%;
    z-index: -2;
    transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
  }
  
  .category-sec h3 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
    z-index: 3;
  }
  
  .category-sec p {
    color: #000;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    z-index: 3;
  }
  
  .category-sec .icon-wrapper {
    background-color: #2c7bfe;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
    z-index: 3;
  }
  
  .category-sec .service-card:hover:after {
    top: 0%;
  }
  
  .category-sec .icon-wrapper {
    background-color: #ffffff;
    color: rgb(255, 23, 131);
  }
  
  .category-sec .service-card:hover .icon-wrapper {
    color: #0dcaf0;
  }
  
  .category-sec .service-card:hover h3 {
    color: #ffffff;
  }
  
  .category-sec.service-card:hover p {
    color: #f0f0f0;
  }
  /* ADVERTISERS SERVICE CARD ENDED */
  